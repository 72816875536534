import APIService from "./api.service";

class GalleryService {

    async getGallery(folder =null) {
        let url = '/custom/gallery';
        if(folder){
            url+='/'+folder;
        }
        return await APIService.get(url);
    }
}

export default new GalleryService();