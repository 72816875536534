import APIService from "./api.service";

class MailService {

    async sendMail(params) {
        let url = '/custom/mail';
        return await APIService.post(url,params);
    }
}

export default new MailService();