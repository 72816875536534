<template>
  <v-container grid-list-xl>
    <v-snackbar
        v-model="snackbar"
        absolute
        bottom
        :timeout=snackBarTimeOut
        :color=snackBarColor
    >
      <span v-html="snackbarText"></span>
      <v-btn
          dark
          icon
          right
          @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card class="elevation-2 white">
          <v-card-title primary-title class="entete headline blue darken-2 white--text" id="contact">
            Contactez-nous
          </v-card-title>
          <v-card-title class="subheading xs12">Le jeu sous toute ses formes est à porté de click.
          </v-card-title>
          <v-card-text class="white">
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-action>
                  <v-icon class="blue--text text--lighten">mdi-map-marker</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Besançon, FR</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-form @submit="onSubmit" ref="form" lazy-validation class="white">
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                    label="Nom et prénom, société *"
                    hint="Votre nom, prénom et/ou raison sociale"
                    persistent-hint
                    required
                    v-model="name"
                    :rules="nameRules"
                    name="name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="E-mail *" required
                    hint="Votre adresse e-mail valide"
                    :rules="emailRules"
                    v-model="email"
                    name="email">

                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    v-model="message"
                    auto-grow
                    color="blue"
                    label="Votre message"
                    rows="10"
                    :rules="messageRules"
                    name="message"
                    class="message"
                ></v-textarea>
              </v-col>
            </v-form>
            <v-progress-linear :indeterminate="true" v-if="isSending"></v-progress-linear>
            <small class="mt-0">* champs obligatoires</small>
          </v-card-text>
          <v-card-actions class="justify-center row wrap">
            <v-row class="pb-2" align="center" justify="center">
              <v-btn color="blue" dark class="pa-2 ma-2" @click.native="onSubmit">
                <v-icon class="white--text text--lighten">mdi-email</v-icon>
                Contactez-nous
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MailService from '../services/mail.service'

export default {
  name: "Contact",
  data: () => ({
    isSending: false,
    valid: false,
    snackbar: false,
    snackbarText: 'Message envoyé',
    snackBarColor: 'success',
    snackBarTimeOut: 10000,
    name: '',
    nameRules: [
      v => !!v || 'Nom requis',
      v => v.length >= 5 || 'Le champs nom doit avoir au moins 5 caractères'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail requis',
      v => /.+@.+/.test(v) || 'L\'adresse email doit être valide'
    ],
    message: "",
    messageRules: [
      v => !!v || 'Message requis',
    ]
  }),
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.isSending = true
        const params = new URLSearchParams()
        params.append('name', this.name)
        params.append('email', this.email)
        params.append('message', this.message)
        MailService.sendMail(params)
            .then(response => {
              // JSON responses are automatically parsed.
              this.posts = response.data
              this.isSending = false
              if (this.posts != 'OK') {
                this.snackbar = true
                this.snackBarColor = 'error'
                this.snackbarText = 'Votre message n\'a pas réussi à être transmis, vous pouvez envoyer un mail à <a href=\'mailto:contact@collectif-ludique-bisontin.fr\'>contact@collectif-ludique-bisontin.fr</a>'
              } else {
                this.isSending = false;
                this.dialog = false;
                this.snackbar = true
                this.snackBarColor = 'success'
                this.snackbarText = 'Votre message a été envoyé avec succès !'
                this.$refs.form.reset();

              }
            })
            .catch(e => {
              this.isSending = false
              this.snackbar = true
              this.snackBarColor = 'error'
              this.snackbarText = 'Votre message n\'a pas réussi à être transmis, vous pouvez envoyer un mail à <a href=\'mailto:contact@collectif-ludique-bisontin.fr\'>contact@collectif-ludique-bisontin.fr</a>'
              this.errors.push(e)
            })
      } else {
        this.snackbar = true
        this.snackBarColor = 'error'
        this.snackbarText = 'Vérifiez votre formulaire avant envoi'
      }
    },
  },

}
</script>

<style scoped>

</style>