<template>
  <v-container :fluid="currentIsImageFolder" class="pa-0 ma-0">
    <v-btn color="blue" dark v-if="currentPath" class="mb-10 mt-5" @click="goBack">
      <v-icon class="mr-1">mdi-arrow-left-circle</v-icon>
      Retour
    </v-btn>
    <v-row justify="center" class="text-center" no-gutters>
    <v-progress-circular v-if="loading" indeterminate color="orange" class="ma-auto" size="200"></v-progress-circular>
    </v-row>
    <v-dialog
        v-model="dialog"
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row no-gutters justify="center" align="center" class="text-center black">
          <v-btn
              color="orange"
              dark
              x-large
              v-bind="attrs"
              v-on="on"
              v-show="currentIsImageFolder"
              class="mt-5  mb-5 ma-auto"
          >
            <v-icon>mdi-play</v-icon>
            Regarder
          </v-btn>
        </v-row>
      </template>
      <v-btn
          icon
          dark
          @click="dialog = false"
          x-large
          right
          fixed
          style="z-index: 9999"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-img v-if="currentIndex" :src="getImageUrl(currentImages[currentIndex],true)" contain height="100vh"
             class="black"></v-img>
      <v-carousel class="black" cycle interval="3000" continuous hide-delimiters v-else height="100vh">
        <v-carousel-item
            v-for="(image,i) in currentImages"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
        >
          <v-img :src="getImageUrl(image,true)" contain height="100vh" class="black"></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
    <v-row class="pa-3" align="center" justify="center">
      <v-col cols="6" sm="2" lg="2" :key="index" v-for="(folder,index) in gallery">
        <v-card @click="loadGallery(folder)" style="cursor: pointer">
          <v-card-title class="card-title not-more-than-3-lines" v-text="folder.folderName"/>
          <v-img contain :src="getHeaderUrl(folder)" height="200px" v-if="folder.hasHeader"/>
          <v-img contain :src="require('@/assets/image/ludinam_2019.png')" height="200px" v-else/>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class=" pa-0 ma-0 black mb-5">
      <v-col cols="6" sm="3" md="3" lg="2" xl="2" v-for="(image,index) in currentImages" :key="image">
        <v-img :src="getImageUrl(image)" contain max-height="150px" @click="showImage(index)" style="cursor: pointer"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GalleryService from '../services/gallery.service'
import {ENTRYPOINT} from "../config/entrypoint";

export default {
  name: "Galerie",
  data: () => ({
    gallery: [],
    loading: true,
    currentPath: null,
    currentIsImageFolder: false,
    currentImages: [],
    dialog: false,
    currentIndex: 0,

  }),
  created() {
    this.loadGallery();
  },
  methods: {
    getImageUrl(image, hd = false) {
      let resolution = hd ? '/hd/' : '/mini/';
      return ENTRYPOINT + '/media/gallery/' + this.currentPath + resolution + image;
    },
    getHeaderUrl(folder) {
      return ENTRYPOINT + '/media/gallery/' + (this.currentPath ? this.currentPath + '/' : '') + folder.folderName + '/header.jpg';
    },
    goBack() {
      let folder = this.currentPath.substr(0, this.currentPath.lastIndexOf('/'));
      GalleryService.getGallery(folder).then(gallery => {
        this.setGallery(gallery);
      })
    },
    showImage(index) {
      this.dialog = true;
      this.currentIndex = index
    },
    loadGallery(folder = null) {
      let folderName = folder ? folder.folderName : '';
      this.currentPath ? this.currentPath += '/' + folderName : this.currentPath = folderName;
      this.loading=true;
      GalleryService.getGallery(this.currentPath).then(gallery => {
        this.setGallery(gallery);
      }).finally(()=>{
        this.loading=false
      })
    },
    setGallery(gallery) {
      this.gallery = gallery.data.folders;
      this.currentPath = gallery.data.currentFolder;
      this.currentImages = gallery.data.images;
      this.currentIsImageFolder = Object.keys(gallery.data.images).length > 0;
    }
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.currentIndex = null;
      }
    }
  }
}
</script>

<style scoped>
.not-more-than-3-lines {
  line-height: 1.4em;
  height: 4.5em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  text-align: center;
}
</style>