<template>
  <v-container :fluid="!$vuetify.breakpoint.mobile" :class="$vuetify.breakpoint.mobile?'pa-0 ma-0':''">
    <v-row no-gutters class="pa-0 ma-0">
      <v-img :src="backgroundImage" max-height="150px">
        <h1 class="actu_title ml-1 mt-1">Actualité</h1>
      </v-img>
    </v-row>
    <v-row align="center" justify="center" class="mt-5 mb-5" no-gutters>
      <v-pagination
          v-model="page"
          :length="maxNbPages"
      ></v-pagination>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="6" v-for="article in articles" :key="article.id" align-self="center"   >
        <v-card elevation="5" class="mb-3 ma-sm-5 ma-lg-10" :to="{ name: 'Article', params:{articleId:article.id} }">
          <v-img :src="getFullURL(article.mediaObjectImage)" cover max-height="250px"/>
          <v-card-title class="card-title-2" v-text="article.titre">
          </v-card-title>
          <v-spacer></v-spacer>
<!--          <v-card-text v-html="article.contenu" style="height: 100px" class="not-more-than-3-lines"></v-card-text>-->
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer/>
            <router-link class="link" :to="{ name: 'Article', params:{articleId:article.id} }">
              Lire l'article...
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-5 mb-15" no-gutters>
      <v-pagination
          v-model="page"
          :length="maxNbPages"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
import ArticleMixin from "../mixins/ArticleMixin";
import MediaObjectMIxin from "../mixins/MediaObjectMixin";
import {mapActions} from "vuex";
import {MEDIA_SERVER} from "../config/entrypoint";

export default {
  name: "Actualite",
  mixins: [ArticleMixin, MediaObjectMIxin],
  data() {
    return {
      page: 1
    }
  },
  computed: {
    backgroundImage() {
      return this.$announcedEvent ? MEDIA_SERVER + this.$announcedEvent.imageUrl : "../assets/image/social-media.jpg";
    }
  },
  methods: {
    ...mapActions({
      getArticles: "article/getArticles",
    })
  },
  created() {
    this.getArticles();
  },
  watch: {
    page: function (before, after) {
      if (before != after) {
        this.getArticles({page: this.page})
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.not-more-than-3-lines {
  line-height: 1.4em;
  height: 4.5em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}
.actu_title{
    font-size: 1em;
    font-weight: bold;
    color: ghostwhite;
    text-transform: uppercase;
    font-family: 'Rammetto One', cursive;
    text-shadow: 2px 2px 8px #010106;
}
main {
  .v-card__title {
    font-family: $font-titre;
    font-weight: bold;
    justify-content: center;
    color: ghostwhite;
    background-color: $orange;
  }

  .v-card__text {
    font-family: $font;
    background-color: #eeeeee;

    span {
      font-weight: bold;
    }
  }
}
</style>
